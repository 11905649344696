.productFormWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 30%);
}

.titleProductContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.photoProduct {
  display: flex;
  width: 20%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.mobilePhotoProduct {
  display: flex;
  width: 7%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.nameProduct {
  display: flex;
  width: 40%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.mobileNameProduct {
  display: flex;
  width: 37%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.countProduct {
  display: flex;
  width: 20%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.priceProduct {
  display: flex;
  width: 10%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.mobilePriceProduct {
  display: flex;
  width: 14%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.allpriceProduct {
  display: flex;
  width: 10%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.productContainer {
  display: flex;
  width: 100%; 
  align-items: center; 
  border-top: 1px solid rgb(211 241 255);
  flex-direction: column; 
}

.transportContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(211 241 255);
}

.salesContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(47, 176, 234);
}

.priceContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(211 241 255)
}

.textTitleForm {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.mobileTextTitleForm {
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
}

.productFormTitle {
  display: flex;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  box-sizing: border-box;
}

.mobileProductFormTitle {
  display: flex;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: right;
}

.productFormPrice {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.productFormPriceWidth {
  display: flex;
  width: 13%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileProductFormPrice {
  display: flex;
  width: 30%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}