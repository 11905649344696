.answerblock_container{display:flex;flex-direction:column;width:100%;height:auto;padding-left:8%;padding-bottom:100px;cursor:pointer;background-color:#f5faff;box-sizing: border-box;}
.mobileAnswerBlock_container{display:flex;flex-direction:column;width:100%;height:auto;padding-left:0%;cursor:pointer;background-color:#f5faff;box-sizing: border-box;}
.answerblock_container_header{width:547px;height:auto;margin:0 533px 49px 0;font-family:Montserrat;font-size:50px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.2;letter-spacing:3px;color:#212629;font-family:Montserrat-Bold}
.mobileAnswerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobileAnswerText {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
}
.mobileAnswerButtons_container {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
}
.mobileAnswerButtons_Telegram {
  display: flex;
  width: 33%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  cursor:text; 
  color:inherit;
  text-decoration:none; 
}
.mobileAnswerButtons_Phone {
  display: flex;
  width: 33%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor:text; 
  color:inherit;
  text-decoration:none; 
}
.mobileAnswerImg_container {
  display: flex;
  width: 100%;
  height: 403px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.blockName{
  display: flex;
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  top: 76px;
}
.blocked{
  display: flex;
  width: 40%;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: #141414; 
  color: #f5faff;
}
.mobileAnswerWrapperButtons_Phone {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: #00b779;
  border-radius: 50px;
}
