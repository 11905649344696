.answer_container{ 
  width: 97%;
  padding: 3%; 
  border-radius: 5px;
  margin: 10px 0px;
  display: flex; 
  flex-direction: column;
  box-sizing: border-box; 
  background-color: #f7f7f7;
}
 
.answer_cross{display: flex; align-items:center;justify-content:space-between;flex-direction:row;width:100%;height:30px}.cross_rotate{transform:rotate(45deg);margin-right:30px}
.answer_title{font-size:20px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.17;letter-spacing:1.15px;color:#212629;font-family:'Montserrat-Bold'}
.mobileAnswerTitle{font-size:20px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.17;letter-spacing:1.15px;color:#212629;font-family:'Montserrat-Bold'}
.answer_description{display: flex; flex-direction: column; align-items: center;}.on_crooss{animation:rotateCross .5s ease forwards}.off_crooss{animation:antirotateCross .5s ease forwards}@keyframes rotateCross{from{transform:rotate(0deg)}to{transform:rotate(90deg)}}@keyframes antirotateCross{from{transform:rotate(90deg)}to{transform:rotate(0deg)}}