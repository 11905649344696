.catalog_wrapper {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 180px; */
  flex-wrap: wrap;
} 

.titleFilter {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.titleFilterMobile {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
 
.catalog_wrapper .answer_container:nth-child(1) { 
  border: 1px solid #d9d9d9;
  background-color: #f7f7f7;
}
 
.answer_description .answer_container:nth-last-child(-n+6) {
  /* background-color: black; */
  border: none;
}

.roundBtnContainer {
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px #d9d9d9 solid;
  background-color: #31bcea; 
  z-index: 2;
}


.element {
	height:1000px;
	background-color: #ededed;
	font-size: 45px;
	border-top:1px solid #000;
	padding-top:55px;
	padding-left:10px;
}

.element.no-padding{
	padding-top:0;
}