.payFormWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 30%);
}

.titlepayFormContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.payFormContainer {
  display: flex;
  width: 100%;
  height: 250px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  flex-direction: column;
  border-top: 1px solid rgb(47, 176, 234)
}

.pay {
  display: flex;
  width:300px;
  /* height: 20px; */
  align-items: center;
  resize: none;
  /* padding: 20px; */
  box-sizing: border-box;
}

.titlePay {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.textTitlepayForm {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.check_container {
  width: 20px;
  height: 20px;
}

input[type="radio"] {
  background-color: #dfdfdf; 
  border-radius: 10px;
  box-shadow: inset 0 1px 1px hsl(0deg 0% 100% / 80%);
  cursor: pointer;
  display: inline-block;
  height: 15px;
  border: 1px solid rgb(47, 176, 234);
  position: relative;
  width: 15px;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  /* background-color: #444; */
  border-radius: 25px;
  /* box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.4),
              0 1px 1px hsla(0,0%,100%,.8); */
  content: '';
  display: block;
  height: 7px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 7px;
}

input[type="radio"]:checked:after {
  background-color: rgb(47, 176, 234);
  box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.4),
              inset 0 2px 2px hsla(0,0%,100%,.4),
              0 1px 1px hsla(0,0%,100%,.8),
              0 0 2px 2px rgb(47, 176, 234);
}