.aboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 50px;
  background-color: white;
}

.mobileAboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  background-color: white;
}

.mobileAboutWrapper .aboutTextWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10px 0px 10px;
  background-color: white;
}

.aboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.mobileAboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.aboutBottomWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.mobileAboutBottomWrapper {
  display: flex;
  width: 100%;
  background-color: #f5faff;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.aboutTextWrapper {
  font-size: 17px;
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  font-family: "Lora",sans-serif;
  justify-content: center;
  line-height: 1.3;
  font-weight: 400;
  white-space: pre-wrap;
}

.aboutPhotoWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileAboutWrapper .aboutPhotoWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.photoAbout {
  width: 90%;
}

.centerTextAbout .titleText {
  text-align: center;
}

.orderTopAbout {
  padding: 40px 0px; 
}