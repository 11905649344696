 
.titleLocationContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.payFormContainer {
  display: flex;
  width: 100%;
  height: 230px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  flex-direction: column;
  border-top: 1px solid #ea402f
}

.locationInput {
  display: flex;
  width: 80%;
  margin-left: 20px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #eee;
  box-shadow: none;
  background: #fcfcfc;
}

.locationInputWidth {
  display: flex;
  width: 20%;
  margin-right: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #eee;
  box-shadow: none;
  background: #fcfcfc;
}

.locationContainer {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  resize: none;
  padding: 20px;
  box-sizing: border-box;
}
 

.titlelocation {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.textTitleForm {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

input[type="number"]:focus {
  outline: none;
  box-shadow: none;
}
 
input[name="city"]:focus {
  outline: none;
  box-shadow: none;
}
 
input[name="add"]:focus {
  outline: none;
  box-shadow: none;
}