.topMonumentWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}

.cardMonumentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: white;
}

.mobileTitleMonumentContainer {
  display: flex; 
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 10px;
  box-sizing: border-box;
  color: #333;
  font-size: 20px;
  font-weight: 400; 
  text-align: center;
}

.mobileSubTitleMonumentContainer {
  display: flex; 
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 16px;
  line-height: 1.2;
  box-sizing: border-box;
  color: #333;
  font-size: 16px;
  font-weight: 400; 
  text-align: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-self: center;
  height: 100px;
}
