.locationFormWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 30%);
}

.titleLocationContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.payFormContainer {
  display: flex;
  width: 100%;
  height: 230px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  flex-direction: column;
  border-top: 1px solid rgb(47, 176, 234)
}
 
.error_input{border:2px solid #e4292b!important}

.downloadInput {
  display: flex;
  width: 30%;
  height: 30px;
  margin-left: 10px;
}

input[type="button"] {
  appearance: auto;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  padding: 1px 50px;
  cursor: pointer;
  border: none;
  background-color: #ea402f;
  color: #fff;

}

input[type="text"]:focus {
  outline: none;
  box-shadow: none;
}

input[type="date"]:focus {
  outline: none;
  box-shadow: none;
}

input[name="telephone"]:focus {
  outline: none;
  box-shadow: none;
}
 
.locationContainer {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  resize: none;
  padding: 20px;
  box-sizing: border-box;
}

.locationForm {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  box-sizing: border-box;
}

.titlelocation {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.textTitleForm {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.input__wrapper {
  width: 190px;
  height: auto;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 25px;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  appearance: auto;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  box-sizing: border-box;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: linear-gradient(0deg, rgb(47, 176, 234) 0%, rgb(47, 176, 234) 100%);
  color: #fff;
  box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%), 7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%);
  outline: none;
  overflow: hidden;
}

@media (max-width: 420px) { 
  .input__file-button { 
    padding: 10px 15px !important ;
  }
  .input__wrapper {
    width: 137px !important ;
  }
  .mobileProductFormTitle { 
    width: 100% !important ; 
  }
  .locationInput { 
    margin-left: 0px !important ;
  }
  .productFormTitle { 
    width: 40% !important ;
    padding-right: 14px !important ;
 }
}

.titleSetup {
  margin-left: 10px;
  color: rgb(47, 176, 234);
  font-size: 15px;
  
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  top: -2px;
  left: 0;
  border: 1px solid #555;
  border-radius: 2px;
  background-color: #f9dedc;
}
input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  top: -2px;
  left: 0;
  background-color:#1E80EF;
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 6px;
}

.fileImage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px; 
  height: 100px;
  margin: 0px 8px;
  /* height: auto; */
  list-style-type: none;
}

.thumb {
  width: 100%;
  height: 75%;
}

.img-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
}

.close {
  color: rgb(47, 176, 234);   
  font-size: 23px;
  cursor: pointer;
  user-select: none;
}

.requared {
  color: rgb(47, 176, 234);
  font-size: 23px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: -10px;
  right: 0px;
}
