.productFormWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 30%);
}

.titleProductContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.photoProduct {
  display: flex;
  width: 20%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.nameProduct {
  display: flex;
  width: 40%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.countProduct {
  display: flex;
  width: 20%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.priceProduct {
  display: flex;
  width: 10%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.allpriceProduct {
  display: flex;
  width: 10%;
  height: 40px;
  align-items: center;
  justify-content: center;
}
 
.transportContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(211 241 255);
}

.salesContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(47, 176, 234);
}

.priceContainer {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(211 241 255);
}

.textTitleForm {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.productFormTitle {
  display: flex;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  box-sizing: border-box;
}

.productFormPrice {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.check_container {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.label_container {
  width: 400px;
  height: auto;
}

 