.cartBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: white;
  flex-direction: column;
  box-sizing: border-box;
}

.productWrapper {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
}

.mobileProductWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  height: auto;
  align-items: center;
}

.infoWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.infoLeftWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 20px;
}

.infoRightWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 20px;
}

.btnWrapperCart {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 130px; */
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 80px;
  align-items: end;
}

.warningpay {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:30px 0px 10px 0px;
  color: #b3271a;
    font-size: 15px;
}

.resultMssg {
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: #03b2ee;
  padding: 10px;
  font-weight: 700;
}